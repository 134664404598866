<template>
  <div class="form">
    <form @submit.prevent="handleSearch">
      <input
        type="search"
        class="form-control"
        placeholder="Enter Project or Developer..."
        v-model="project"
      />
      <button class="btn" type="submit">Search Projects</button>
    </form>
  </div>
</template>

<script>
// import getProjectSearch from '@/services/projects/getProjectSearch'
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter()
    
    const project = ref('')

    // const { mainData:projects, loadData:loadProjects } = getProjectSearch(project.value)

    // const fetchProjects = (project) => {
    //   if(project.length) {
    //     console.log(project);
    //     loadProjects(project)
    //     console.log(projects.value);
    // @keyup="fetchProjects(project)"
    //   }
    // }

    const handleSearch = () => {

      router.push({ path: '/projects', query: { search: project.value} })
      // console.log(router);

    };

    return { project, handleSearch };
  },
};
</script>

<style scoped>
form {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(0,0,0,.8);
}
.form-control:focus, .form-control:active,
.btn:focus, .btn:active {
  box-shadow: none !important;
  outline: none !important;
  border-color: transparent !important;
}
.btn {
  white-space: nowrap;
  background-color: var(--mainColor);
  color: #ffffff;
}

@media (max-width: 575.98px) {
  form {
    padding: .5rem;
    height: 45px;
  }
  .form-control, .btn {
    font-size: .7rem;
    padding: .3rem;
  }
}
</style>
