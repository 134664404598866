<template>
  <div class="container sell">
    <div class="row">
      <div class="col-12 mb-4 title">
        <h1><span>Sell</span> your property with us</h1>
        <h2>List your property, get the maximum exposure</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7">
        <div class="image"><img src="@/assets/images/building.webp" alt="CAPEX PROPERTIES real estate agency" class="lazy img"></div>
      </div>
      <div class="col-lg-5">
        <h5 class="mb-3">To sell your property, simply complete the form below</h5>
        <ContactusForm subject="Email from Capex Website, Selling" />
      </div>
    </div>
    <div class="row mt-5 content">
      <div class="col-12">
        <p>Or you can email us at <a href="mailto:info@capexproperties.ae" target="_blank">info@capexproperties.ae</a> or whatsapp us on <a target="_blank" :href="isMobile() ? 'https://api.whatsapp.com/send?phone=971502747084&text=Hi.. I want to rent my property, please send me more details. Thanks' : 'https://web.whatsapp.com/send?phone=971502747084&text=Hi.. I want to rent my property, please send me more details. Thanks'">+971502747084</a> .</p>
        <p>Fill out the form above and provide us with the required information to list your property, and leave the rest to us. We will ensure to market it the right way. Our trustworthy relationship team will carry out the procedures required to list the property and obtain clearance from RERA.</p>
      </div>
      <div class="col-12 mt-5 why-content">
        <h1 class="text-center mb-4">Choose the Best, Choose Capex Properties</h1>
        <p class="text-center">How can Capex Properties help you meet your needs ?</p>
        <p class="text-center">Choosing the right real estate agent is an important decision to make, but you don't have to worry anymore. At Capex Properties, we pride ourselves on our knowledge of the market, buyers, and government processes and regulations, which allows us to negotiate the best possible deal. With great marketing tools, an off-the-shelf database of pre-approved clients, and the best ability to close deals, we can bring you the best value in the shortest amount of time.</p>
      </div>
    </div>
  </div>
</template>

<script>
import isMobile from '@/services/isMobile'
import ContactusForm from '../components/ContactusForm.vue'
import { useHead } from '@vueuse/head'
export default {
  components: {
    ContactusForm
  },
  setup() {

    useHead({
      meta: [
        {
          name: `description`,
          content: `Sell your property with Capex Properties. Provide us with the required information to list your property, and leave the rest to us, we can bring you the best value in the shortest amount of time.`
        },
        {
          property: `og:title`,
          content: `Sell Your Property | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `Sell your property with Capex Properties. Provide us with the required information to list your property, and leave the rest to us, we can bring you the best value in the shortest amount of time.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/sell-your-property`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/sell-your-property`
        }
      ]
    })
    
    
    return{isMobile}
  }
}
</script>

<style scoped>
.sell {
  min-height: 30rem;
  margin-top: 10rem;
}
.title h1, .title h2 {
  text-align: center;
  font-weight: bold;
}
.title h1 {
  text-transform: capitalize;
}
.title h1 span {
  color: var(--mainColor);
}
.image {
  height: 20rem;
}
img {
  object-fit: fill;
}
.content a {
  color: var(--mainColor);
  font-weight: bold;
}
.downloads {
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloads a{
  background-color: var(--mainColor);
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 1rem;
}
.why-content h1 {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 575.98px) {
  .image {
    height: 10rem;
  }
}
</style>