<template>
  <div class="container-fluid mt-5 footer">
    <div class="row top-footer">
      <div class="col-12 col-lg-3 col-sm-6 mt-4">
        <div class="logo">
          <router-link to="/">
            <h1>capex properties</h1>
          </router-link>
        </div>
        <div class="socials">
          <a class="facebook" target="_blank" href="https://www.facebook.com/capexproperties.ae"
            ><FontAwesome :icon="['fab', 'facebook-f']"
          /></a>
          <a class="instagram" target="_blank" href="https://www.instagram.com/capexproperties"
            ><FontAwesome :icon="['fab', 'instagram']"
          /></a>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-sm-6 mt-4">
        <div class="quick-links">
          <h5>Quick Links</h5>
          <ul>
            <li>
              <router-link :to="{ name: 'allProperties' }"
                ><i><FontAwesome icon="angle-right" /></i> Properties</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'allProjects' }"
                ><i><FontAwesome icon="angle-right" /></i> All Projects</router-link
              >
            </li>
            <li><router-link :to="{ name: 'about' }"><i><FontAwesome icon="angle-right" /></i> About Us</router-link></li>
            <li>
              <router-link :to="{ name: 'contactus' }"><i><FontAwesome icon="angle-right" /></i> Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-sm-6 mt-4">
        <div class="footer-contactus">
          <h5>Get In Touch</h5>
          <ul>
            <li class="title"><i><FontAwesome icon="phone" /></i> Call:</li>
            <li><a href="tel:+971502747084"><span>Mobile: </span>+971502747084</a></li>
            <li><a href="tel:+97143211210"><span>Landline: </span>+97143211210</a></li>
          </ul>
          <ul>
            <li class="title"><i><FontAwesome icon="envelope" /></i> Email Address:</li>
            <li><a href="mailto:info@capexproperties.ae">info@capexproperties.ae</a></li>
          </ul>
          <ul>
            <li class="title"><i><FontAwesome icon="location-dot" /></i> Location:</li>
            <li><a href="https://goo.gl/maps/48hLsF9GWbRpvS1P7" target="_blank">Office 303, Opal Tower, Business Bay, Dubai, UAE - PO.Box 410714</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-sm-6 mt-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14442.082875081918!2d55.26692896977539!3d25.1856563!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6831f6693c85%3A0xb5b269f7897f4978!2sOpal%20Tower%20-%20Business%20Bay%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2snl!4v1652713241399!5m2!1sen!2snl"
          width="100%"
          height="200"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="row rights">
      <p>© 2022 All Rights Reserved to CAPEX PROPERTIES Real Estate Agency.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  border-top: 1px solid var(--mainColor);
}
.top-footer {
  padding: 1rem;
}
.logo h1 {
  text-transform: uppercase;
  color: var(--mainColor);
  font-weight: bolder;
}
.socials {
  margin-top: 1rem;
}
.socials a {
  font-size: 1.5rem;
  margin-right: 2rem;
}
.rights {
  background-color: var(--mainColor);
}
.rights p {
  margin: 1rem 0;
  font-size: 0.7rem;
  font-weight: bold;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  margin-top: 1rem;
}
.footer-contactus ul {
  margin-bottom: 1rem;
}
.footer-contactus ul li {
  margin-top: 0;
}
a:hover {
  color: var(--mainColor);
}
ul li i {
  color: var(--mainColor);
}
h5 {
  font-weight: bold;
  color: var(--mainColor);
}
li.title {
  color: var(--mainColor);
  font-weight: bold;
  font-size: .9rem;
}
@media (max-width: 575.98px) {
  .rights p {
    font-size: .5rem;
  }
}
</style>
