<template>
  <div class="container contactUs">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center fw-bold mb-5">GET IN TOUCH</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <div class="shadow-sm p-4 mb-3 contact-box">
          <h4 class="fw-bold mb-3 text-uppercase">Send us a message</h4>
          <ContactusForm subject="Email from Capex Website"/>
        </div>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-12">
            <div class="p-4 info-box">
              <h4 class="fw-bold mb-4 text-uppercase text-light">contact info</h4>
              <a href="https://goo.gl/maps/48hLsF9GWbRpvS1P7" target="_blank">
                <div class="d-flex align-items-center mb-3 location">
                  <i class="flex-shrink-0 d-flex align-items-center justify-content-center me-2 bg-light p-2 rounded-circle"><FontAwesome  icon="location-dot" /></i>
                  <p class="m-0  text-light">Office 303, Opal Tower, Business Bay, Dubai, UAE - PO.Box 410714</p>
                </div>
              </a>
              <a href="mailto:info@capexproperties.ae" target="_blank">
                <div class="d-flex align-items-center mb-3 mail">
                  <i class="flex-shrink-0 d-flex align-items-center justify-content-center me-2 bg-light p-2 rounded-circle"><FontAwesome icon="envelope" /></i>
                  <p class="m-0  text-light">info@capexproperties.ae</p>
                </div>
              </a>
              <a href="tel:+971502747084" target="_blank">
                <div class="d-flex align-items-center mb-3 phone">
                  <i class="flex-shrink-0 d-flex align-items-center justify-content-center me-2 bg-light p-2 rounded-circle"><FontAwesome icon="mobile-alt" /></i>
                  <p class="m-0  text-light">+971502747084</p>
                </div>
              </a>
              <a href="tel:+97143211210" target="_blank">
                <div class="d-flex align-items-center mb-3 phone">
                  <i class="flex-shrink-0 d-flex align-items-center justify-content-center me-2 bg-light p-2 rounded-circle"><FontAwesome icon="phone" /></i>
                  <p class="m-0  text-light">+97143211210</p>
                </div>
              </a>
              <div class="d-flex mt-4 social">
                <a class="facebook" target="_blank" href="https://www.facebook.com/capexproperties.ae"><i class="text-light ms-1 fs-4"><FontAwesome :icon="['fab', 'facebook-f']"/></i></a>
                <a class="instagram" target="_blank" href="https://www.instagram.com/capexproperties"><i class=" ms-4 text-light fs-4"><FontAwesome :icon="['fab', 'instagram']"/></i></a>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mt-3 map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14442.082875081918!2d55.26692896977539!3d25.1856563!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6831f6693c85%3A0xb5b269f7897f4978!2sOpal%20Tower%20-%20Business%20Bay%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2snl!4v1652713241399!5m2!1sen!2snl" width="100%" height="200" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactusForm from '@/components/ContactusForm.vue' 
import { useHead } from '@vueuse/head'
export default {
  components: {
    ContactusForm
  },
  setup() {

    useHead({
      meta: [
        {
          name: `description`,
          content: `Do not hesitate to contact us if you have any questions, problems or suggestions. Contact information for Capex Properties location, phone number, email address is shown here.`
        },
        {
          property: `og:title`,
          content: `Contact Us | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `Do not hesitate to contact us if you have any questions, problems or suggestions. Contact information for Capex Properties location, phone number, email address is shown here.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/contact-us`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/contact-us`
        }
      ]
    })
  },
}
</script>

<style scoped>
.contactUs {
  margin-top: 6rem;
}
.info-box {
  background-color: var(--mainColor);
}
i {
  width: 35px;
  height: 35px;
}
@media (min-width: 576px) { 
  .contactUs {
    margin-top: 10rem;
  }
}
</style>